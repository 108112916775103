.send-form--label {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
}

.send-form--swap-networks {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background: linear-gradient(141.43deg, #7c82ff 6.16%, #b07fe0 106.03%);
  border: none;
  border-radius: 100px;
}

.send-form--error {
  margin-bottom: 0;
  padding-left: 20px;
  overflow: hidden;
}
