.coin-info--tokens {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
}

.coin-info--tokens-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-left: -5px;
  background: #fff;
  border-radius: 100px;
  box-shadow: 0px 4px 8px 3px #00000026,
    0px 1px 3px 0px #0000004D;
}

.coin-info--tokens-item:first-child {
  margin-left: 0;
  z-index: 1;
}

.coin-info--tokens-item img {
  display: block;
  min-width: 12px;
  width: 12px;
  height: 12px;
  object-fit: contain;
}
