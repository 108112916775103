.explorer-wallet-group {
  display: flex;
  gap: 24px;
}

.explorer-wallet-group--item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.explorer-wallet-group--label {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #a39dc7;
}

.explorer-wallet-group--logo {
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.explorer-wallet-group--value {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 32px;
  background: transparent;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
}

.explorer-wallet-group--connect-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  padding: 7px 8px 7px 16px;
  background: linear-gradient(94.33deg, #a77eea 7.8%, #5b51cf 103.43%);
  border: none;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  outline: none;
}

.explorer-wallet-group .wallet-popover--trigger-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  width: 18px;
  height: 18px;
  margin-left: -8px;
  padding: 0;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.wallet-popover--trigger-icon img {
  border-radius: 50%;
}

.wallet-popover--trigger-icon:first-child {
  margin-left: 0;
}

.explorer-wallet-group--connect-additional {
  display: flex;
  align-items: center;
  width: max-content;
  gap: 4px;
  padding: 7px 8px;
  border: 1px solid #b19eff1f;
  background: #d1cdff26;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
}

.explorer-wallet-group--divider {
  width: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.explorer-wallet-popover--content {
  z-index: 50;
  display: flex;
  flex-direction: column;
  min-width: 215px;
  padding: 20px 16px;
  background: linear-gradient(
    180deg,
    rgba(100, 81, 219, 0.3) 0%,
    rgba(71, 56, 162, 0.126) 100%
  );
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  box-shadow: 0px 10px 40px 0px #03001666;
  backdrop-filter: blur(30px);
}

.explorer-wallet-popover--content .wallet-popover--menu-item--icon {
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.wallet-popover--menu-item--icon img {
  border-radius: 50%;
}

.explorer-wallet-popover--divider {
  margin-top: 14px;
  border-bottom: 0.5px solid #b5b2c933;
}

.explorer-wallet-popover--button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 14px;
  padding: 8px 23px;
  background: transparent;
  border: 1px solid #816fc766;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
}

.explorer-wallet-popover--button .text-gradient {
  background: linear-gradient(89.33deg, #dbbeff 6.65%, #aea1ff 99.6%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 768px) {
  .explorer-wallet-group {
    gap: 32px;
  }
}
