.fee-details--container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0 0 12px 12px;
}

.fee-details--item {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.fee-details--label {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #9794af;
}

.fee-details--value {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
}

.swap-fee-gradient {
  background: linear-gradient(267.09deg, #77bde7 2.53%, #7fa3ff 89.39%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fee-details--value-usd {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  color: #a39dc7;
}

.fee-details--value-usd.text-gradient {
  background: linear-gradient(92.27deg, #bc4357 -33.8%, #ecb59b 92.11%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fee-details--nft-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  padding: 0 8px;
  border-radius: 100px;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  color: #fff;
}

.fee-details--nft-badge.late {
  background: linear-gradient(90deg, #bb3f55 0%, #e9ab88 100%);
}

@media screen and (max-width: 480px) {
  .fee-details--nft-badge {
    font-size: 7px;
  }
}
