.wallet-popover--trigger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 42px;
  height: 42px;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  outline: none;
}

.wallet-popover--trigger-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: -8px;
  padding: 0;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.wallet-popover--trigger-icon img {
  border-radius: 50%;
}

.wallet-popover--trigger-icon:first-child {
  margin-left: 0;
}

.wallet-popover--menu {
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--radix-popover-content-available-width);
  max-width: 239px;
  padding: 20px 16px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(40px);
  border-radius: 12px;
  z-index: 1000;
  color: #fff;
  transform-origin: 50% 0%;
}

.wallet-popover--menu::after {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid #cfc3ff4d;
  border-radius: inherit;
  z-index: 1;
  pointer-events: none;
}

.wallet-popover--menu-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 12px;
  margin-top: 12px;
  border-top: 0.5px solid #b5b2c933;
}

.wallet-popover--menu-item:first-child {
  padding-top: 0;
  margin-top: 0;
  border-top: none;
}

.wallet-popover--menu-item--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 100px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.wallet-popover--menu-item--icon img {
  border-radius: 50%;
}

.wallet-popover--menu-item--label {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #a39dc7;
}

.wallet-popover--menu-item--address {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.wallet-popover--menu-item--action {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  color: #ff9eb6;
}

.wallet-popover--disabled {
  color: #d2c6ff99;
}

.wallet-popover--domains {
  margin-top: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.wallet-popover--domains thead {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}

.wallet-popover--domains th {
  padding: 0;
  font-weight: 500;
  color: #d2c6ff99;
}

.wallet-popover--domains td {
  padding: 4px 0 4px 0;
}

.wallet-popover--domains-no {
  width: 20px;
  padding: 4px 4px 4px 0 !important;
}

.wallet-popover--domains-address {
  max-width: 151px;
}

.wallet-popover--domains-action {
  width: 36px;
}

tbody td.wallet-popover--domains-action {
  padding: 4px 9px 4px 0 !important;
}

.wallet-popover--domains-indicator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 12px;
  width: 12px;
  min-height: 12px;
  height: 12px;
  background: #c7b8ff33;
  border-radius: 3px;
  cursor: pointer;
}

.wallet-popover--domains-indicator::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: linear-gradient(111.83deg, #e2bcff 24.5%, #a383ff 96.08%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.wallet-popover--domains-indicator::after {
  content: '';
  position: absolute;
  inset: 3px;
  border-radius: 1px;
  background: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.wallet-popover--domains-indicator.active::before,
.wallet-popover--domains-indicator.active::after {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .wallet-popover--trigger {
    padding: 11px 7px;
    background: rgba(255, 255, 255, 0.06);
  }

  .wallet-popover--trigger::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    border: 1px solid #fff;
    mask: linear-gradient(
      186.06deg,
      #ffffff -164.7%,
      rgba(255, 255, 255, 0) 164.57%
    );
    z-index: 1;
    pointer-events: none;
  }
}
