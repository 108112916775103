.quests-navbar {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  min-height: 52px;
  border-radius: 16px;
  padding: 6px;
  background: rgba(255, 255, 255, .05);
}

.quests-navbar::-webkit-scrollbar {
  display: none;
}

.quests-navbar--item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 7px 16px;
  border-radius: 8px;
  text-decoration: none !important;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: rgba(255, 255, 255, .7);
  transition: .3s ease-in-out;
  cursor: pointer;
}

.quests-navbar--item.active {
  background: #231D46;
}

.quests-navbar--item.active .quests-navbar--item-text {
  background: linear-gradient(90deg, #E5C4FF 0%, #A89AFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.quests-navbar--item-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 15.5px;
  background: linear-gradient(90deg, #BF90E4 0%, #755ADF 99%);
  border-radius: 100px;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .quests-navbar {
    max-width: calc(100vw - 40px * 2 - 40px - 388px);
    overflow: auto;
  }
}

@media screen and (max-width: 768px) {
  .quests-navbar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: calc(100vw - 0px * 2);
    margin: 0 -20px;
    padding: 0;
    border-radius: none;
    background: transparent;
    overflow: hidden;
  }

  .quests-navbar--item {
    padding: 0 !important;
    background: transparent;
    font-size: 14px;
  }

  .quests-navbar--item-badge {
    display: none;
  }

  .quests-navbar--item.active {
    background: transparent;
    opacity: 1;
  }

  .quests-navbar--item.active .quests-navbar--item-badge {
    display: flex;
    padding: 3px 10px;
    font-size: 10px;
  }

  .active .quests-navbar--item-text {
    background: none !important;
    background-clip: unset !important;
    -webkit-text-fill-color: unset !important;
    color: #fff;
  }
}

@media screen and (max-width: 350px) {
  .quests-navbar--item.active .quests-navbar--item-badge {
    display: flex;
    padding: 1px 5px;
    font-size: 8px;
  }
}
