.currency-select--trigger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #fff;
}

.currency-select--trigger--content {
  display: flex;
  align-items: center;
  margin-right: 6px;
  gap: 6px;
}

.currency-select--image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100px;
}

.currency-select--image img {
  min-width: 16px;
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.currency-select--menu {
  display: flex;
  flex-direction: column;
  min-width: 143px;
  background: linear-gradient(
    180deg,
    rgba(152, 140, 221, 0.23) 0%,
    rgba(37, 29, 83, 0.23) 100%
  );
  box-shadow: 0px 14px 30.1px 0px #181136;
  backdrop-filter: blur(30px);
  border-radius: 20px;
  z-index: 9999;
  overflow: hidden;
}

.currency-select--menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background: transparent;
  border: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  transition: background 0.3s ease-in-out;
}

.currency-select--menu-item:first-child {
  padding-top: 13px;
}

.currency-select--menu-item:last-child {
  padding-bottom: 13px;
}

.currency-select--menu-item:hover,
.currency-select--menu-item.active {
  background: rgba(255, 255, 255, 0.1);
}

.currency-select--menu-indicator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-left: auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.currency-select--menu-indicator::after {
  content: '';
  position: absolute;

  min-width: 6px;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 1px;
  opacity: 0;
}

.currency-select--menu-item.active .currency-select--menu-indicator {
  background: linear-gradient(135deg, #e5c4ff 0%, #7d67d9 100%) !important;
}

.currency-select--menu-item.active .currency-select--menu-indicator::after {
  opacity: 1;
}
