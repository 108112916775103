.bridge-phase-banner {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 108px;
  margin: 12px 0 0 0;
  background: linear-gradient(269.95deg, #CC7975 0.03%, #772B3F 44.99%, rgba(89, 82, 134, 0.32) 86%);
  border-radius: 12px;
  padding: 12px 15px 14px 23px;
  text-decoration: none !important;
  overflow: hidden;
}

.bridge-phase-banner.Midday {
  background: linear-gradient(269.95deg, #61B4EF 0.03%, #2B4998 44.99%, rgba(29, 70, 132, 0.28) 99.94%);
}

.bridge-phase-banner::before {
  content: '';
  position: absolute;
  inset: 0px;
  pointer-events: none;
  border: 0.5px solid #fff;
  border-radius: inherit;
  mask: linear-gradient(251.86deg, #FFFFFF -17.87%, rgba(255, 255, 255, 0) 83.3%);
  z-index: 1;
}

.bridge-phase-banner-content {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  min-width: 234px;
  max-width: 234px;
}

.bridge-phase-banner--heading {
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  text-decoration: none !important;
  color: #fff !important;
}

.bridge-phase-banner--heading img {
  vertical-align: sub;
}

.bridge-phase-banner--action {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: auto;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none !important;
  color: #FF8EA2;
}

.bridge-phase-banner.Midday .bridge-phase-banner--action {
  color: #72C5FF;
}

.bridge-phase-banner--action .text-gradient {
  background: linear-gradient(90deg, #FFD2C0 0%, #FF8EA2 100%) no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bridge-phase-banner.Midday .bridge-phase-banner--action .text-gradient {
  background-image: linear-gradient(90deg, #C0F7FF 0%, #6FC3FF 100%);
}

.bridge-phase-banner--image-container {
  margin-left: auto;
  height: 80px;
}

.bridge-phase-banner--image-container img {
  width: 84px;
}

.bridge-phase-banner-nfts {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
  font-family: Satoshi;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #FFD6CE7A;
}

.bridge-phase-banner.Midday .bridge-phase-banner-nfts {
  color: #89C6FF9E;
}

.bridge-phase-banner-nfts img.phases-NFTs-progress-item {
  margin-left: -3px;
  min-width: 26px;
  width: 26px;
  height: 23px;
}

.bridge-phase-banner-nfts img.phases-NFTs-progress-item:not(.active) {
  filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.25));
}

@media screen and (max-width: 480px) {
  .bridge-phase-banner {
    padding: 10px 11px 11px 16px;
    background: linear-gradient(269.95deg, #CC7975 0.03%, #772B3F 44.99%, rgba(89, 82, 134, 0.32) 99.94%);
  }

  .bridge-phase-banner--image-container {
    margin-top: 4px;
  }

  .bridge-phase-banner--image-container img {
    min-width: 80px;
    width: 80px;
  }
}
