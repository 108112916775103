.advanced-transfer-table {
  width: 100%;
  max-width: 523px;
  margin-top: 32px;
  margin-bottom: 40px;
}

.advanced-transfer-table--header {
  display: grid;
  grid-template-columns: 14px 120px 120px 70px 92px 18px;
  gap: 18px;
}

.advanced-transfer-table--header-item {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #b5b2c9;
  text-wrap: nowrap;
}

.advanced-transfer-table--header-item:nth-child(4) {
  text-align: center;
}

.advanced-transfer-table--header-item:nth-child(5) {
  text-align: right;
}

.advanced-transfer-table--item-wrapper {
  padding-top: 12px;
  border-bottom: 0.5px solid #ffffff1a;
}

.advanced-transfer-table--item-wrapper:last-child {
  border-bottom: none;
}

.advanced-transfer-table--item {
  display: grid;
  grid-template-columns: 14px 120px 120px 70px 92px 18px;
  gap: 18px;
  align-items: start;
  padding: 12px 0;
  border-bottom: 0.5px solid #ffffff1a;
}

@media (max-width: 746px) {
  .advanced-transfer-table--item {
    padding: 0;
    border: none;
    margin-bottom: 12px;
    grid-template-columns: 14px 1fr 1fr 20px 20px;
  }
}

.advanced-transfer-progress-table-grid--item {
  grid-template-columns: 14px 1fr 1fr 20px;
}

.advanced-transfer-table--item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.advanced-transfer-table--item .infoText:not(.text-end) {
  max-width: 84px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.advanced-transfer--item-no {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #a39dc7;
  align-self: center;
  margin-top: 4px;
}

.advanced-transfer--item-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 23px;
  padding: 4px 12px;
  background: #ffffff1a;
  border: 0.5px solid #ffffff80;
  border-radius: 21px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: #ffffff;
}

.advanced-transfer--item-tag.delayed {
  background: #cc8a5a66;
  border: 0.5px solid #f8cbab4d;
  color: #f8cbab;
}

.advanced-transfer--item-tag.completed {
  background: #5fb8513d;
  border: 0.5px solid #ceffe233;
  color: #bdf8ca;
}

.advanced-transfer--item-external {
  margin-left: -2px;
  align-self: center;
}

.advanced-transfer-table--item-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.advanced-transfer-table--item-details-button {
  align-self: center;
}
