.advanced-receiveBlock {
  padding: 12px 20px;
  background: #FFFFFF0D !important;
}

@media (max-width: 746px) {
  .advanced-info-block {
    border-top: 1px solid #2f2855;
  }
}

.advanced-info-amount-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 100%;
}

.advanced-info-amount-icon img {
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.advanced-info-amount {
  display: flex;
  gap: 8px;
  align-items: center;
}

.advanced-info-amount p {
  margin: 0;
  padding: 0;
}
