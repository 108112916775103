.advanced-network-from-wrapper {
  width: 463px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}

.advanced-network-from {
  width: 100%;
  background-color: #ffffff0d;
  padding: 10px 20px;
  height: 54px;
  border-radius: 12px 0px 0px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 746px) {
  .advanced-network-from-wrapper {
    width: 100%;
  }

  .advanced-network-from {
    border-radius: 12px;
  }
}

.advanced-network-user-balance {
  font-weight: 700;
}

.advanced-network-from-icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  max-width: 22px;
  background-color: #fff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.advanced-network-from-icon-img {
  width: 18px;
  height: 18px;
  min-width: 18px;
}

.network-name {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

.advanced-network-from-network {
  border: none;
  background-color: transparent;
  display: flex;
  gap: 6px;
}

.advanced-network-from-network-content {
  display: flex;
  gap: 6px;
  align-items: center;
}

.networks-list-wrapper {
  height: 22px;
}

.networks-list {
  display: flex;
  align-items: center;
}

.btnIcon {
  width: 11px;
  min-width: 11px;
  height: 8px;
}

.advancedCoinBtn {
  min-width: 22px;
  width: 22px;
  max-width: 22px;
  min-height: 22px;
  height: 22px;
  max-height: 22px;
  background-color: #fff;
  margin-left: -8px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 4px 0px 4px 0px #00000040;
}

.advancedCoinBtn img {
  width: 18px;
  max-width: 18px;
  min-width: 18px;
  height: 18px;
}
