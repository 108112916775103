.quests-sidebar {
  --quests-sidebar-background: #110d22;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: max-content;
  max-height: max-content;
  padding-bottom: 35px;
  background: var(--quests-sidebar-background);
  border-radius: 24px;
  z-index: 20;
}

.quests-sidebar::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  pointer-events: none;
  border: 1px solid #fff;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
}

.quests-profile--banner {
  height: 105px;
  border-radius: 24px 24px 0 0;
  overflow: hidden;
}

.quests-profile--banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quests-profile--image {
  position: relative;
  margin: 0 auto;
  min-width: 112px;
  width: 112px;
  height: 112px;
  margin-top: -56px;
  background: var(--quests-sidebar-background);
  border-radius: 18px;
}

.quests-profile--image::before {
  content: '';
  position: absolute;
  inset: -13px -13px 0 -13px;
  border-radius: 25px;
  background: var(--quests-sidebar-background);
}

.quests-profile--image-bg {
  position: absolute;
  top: 30px;
  min-width: 26px;
  width: 26px;
  min-height: 26px;
  height: 26px;
  overflow: hidden;
}

.quests-profile--image-bg.left {
  left: -39px;
}

.quests-profile--image-bg.right {
  right: -39px;
}

.quests-profile--image-bg::before {
  content: '';
  position: absolute;
  inset: 0;
  box-shadow: 0 0 0 13px var(--quests-sidebar-background);
}

.quests-profile--image-bg.left::before {
  border-radius: 0 0 18px 0;
}

.quests-profile--image-bg.right::before {
  border-radius: 0 0 0 18px;
}

.quests-profile--image img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}

.wallet-connected .quests-profile--image img {
  border: 1px solid rgba(255, 255, 255, 0.21);
}

.quests-profile--badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 0 auto 16px auto;
  padding: 8px 14px 8px 14px;
  gap: 10px;
  background: linear-gradient(90deg, #bf90e4 0%, #755adf 99%);
  border-radius: 100px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
}

.quests-profile--level {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.quests-profile--levels {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 35px;
}

.quests-profile--levels-trigger {
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 0 auto;
  background: transparent;
  border: none;
  padding: 0;
  background: linear-gradient(90deg, #e5c4ff 0%, #b2a6ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.quests-profile--levels-item {
  display: flex;
  gap: 12px;
}

.quests-profile--levels-item-image {
  min-width: 32px;
  width: 32px;
  height: 32px;
  margin: auto 0;
  border: 1px solid #fff;
  border-radius: 7px;
  object-fit: cover;
}

.quests-profile--levels-item-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.quests-profile--levels-item-progress {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.4);
}

.quests-profile--levels-item-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 10px;
  margin: auto 0 auto auto;
  background: linear-gradient(90deg, #bf90e4 0%, #755adf 99%);
  border-radius: 19px;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
}

.quests-profile--levels-item-badge.active {
  background: rgba(255, 255, 255, 0.1);
}

.quests-profile--btn {
  width: 100% !important;
  min-height: 50px;
}

.quests-profile--stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.quests-profile--stats-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.quests-profile--stats-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  color: rgba(255, 255, 255, 0.6);
}

.quests-profile--stats-value {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
}

.quests-profile--progress {
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-bottom: 12px;
}

.quests-profile--progress-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 36px;
  overflow: hidden;
}

.quests-profile--progress-bar--filled {
  height: 100%;
  background: linear-gradient(90deg, #9e86ff 0%, #f7c9ff 100%);
  border-radius: inherit;
}

.max-level .quests-profile--progress-bar--filled {
  background: linear-gradient(90deg, #ff86ec 0%, #b2ffab 100%);
}

.quests-profile--progress-hint {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 400px) {
  .quests-profile--progress-hint {
    font-size: 12px;
    line-height: 12px;
  }
}

.max-level .quests-profile--progress-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.7);
}

.quests-profile--progress-hint .text-gradient {
  background: linear-gradient(269.3deg, #e8a8ff 6.44%, #e8a8ff 97.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quests-sidebar--trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: max-content;
  gap: 4px;
  background: transparent;
  border: none;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  opacity: 0.3;
}

.quests-sidebar--links {
  display: none;
}

.quests-sidebar--links-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
  color: #fff !important;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 768px) {
  .quests-sidebar {
    --quests-sidebar-background: #030016;
    position: fixed;
    inset: 0;
    max-height: 100vh;
    max-height: 100svh;
    padding: 0;
    z-index: 20;
    border-radius: 0;
    border: none;
    overflow: auto;
  }

  .quests-sidebar::before {
    display: none;
  }

  .quests-profile {
    margin-top: -97px;
  }

  .quests-profile--banner {
    display: flex;
    justify-content: center;
    align-items: end;
    border-radius: 0;
    height: 187px;
  }

  .quests-profile--image {
    min-width: 92px;
    width: 92px;
    height: 92px;
  }

  .quests-profile--levels {
    padding: 0 30px;
  }

  .quests-sidebar--links {
    display: flex;
    flex-direction: column;
  }
}
