.advanced-transfer-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
}

.advanced-transfer-divider {
  margin: 24px 0;
  border: none;
  height: 1px;
  background: linear-gradient(270deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 47.4%,
      rgba(255, 255, 255, 0) 100%);
  opacity: 0.3;
}

.advanced-summary-block-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #b5b2c9;
}

.advanced-summary-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  background: #FFFFFF0D;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  color: #fff;
}

.advanced-summary-block-small {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #a39dc7;
}

.advanced-summary-header-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff99;
}

.advanced-summary-header-network {
  width: 100%;
  background-color: #ffffff0d;
  padding: 10px 20px;
  height: 54px;
  border-radius: 12px 0px 0px 12px;
  display: flex;
  gap: 6px;
  min-width: 463px;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.advanced-summary-header-token .coinBtn,
.advanced-summary-header-network .coinBtn {
  width: 22px;
  height: 22px;
}

.advanced-summary-header-token .networkIcon,
.advanced-summary-header-network .networkIcon {
  min-width: 18px;
  width: 18px;
  min-height: 18px;
  height: 18px;
}

@media (max-width: 746px) {
  .advanced-summary-header-network {
    width: 100%;
    min-width: 300px;
    border-radius: 12px;
    min-height: 54px;
  }
}

.advanced-summary-header-token {
  display: flex;
  background-color: #ffffff0d;
  border-radius: 0px 12px 12px 0px;
  align-items: center;
  height: 54px;
  min-width: 175px;
  padding: 0 20px;
  height: 54px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

@media (max-width: 746px) {
  .advanced-summary-header-token {
    width: 100%;
    border-radius: 12px;
    min-height: 54px;
  }
}

.advanced-summary-header-token button {
  background: none;
  display: flex;
  gap: 6px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
}
