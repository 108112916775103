.connect-dialog--overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.connect-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 42px;
  width: 100%;
  max-width: 620px;
  height: 100%;
  max-height: 522px;
  margin: auto;
  background: #0e0922;
  border-radius: 20px;
  outline: none;
}

.connect-dialog::before {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid #fff;
  border-radius: inherit;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  pointer-events: none;
}

.connect-dialog--close {
  position: absolute;
  top: 18px;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;
}

.connect-dialog .simplebar-scrollbar::before {
  background-color: #807fb9 !important;
  opacity: 1 !important;
  width: 4px !important;
  top: 4px !important;
  bottom: 4px !important;
  left: 50%;
  transform: translateX(-50%);
}

.connect-dialog .simplebar-track.simplebar-vertical {
  margin: 31px 4px 31px 0;
}

.connect-dialog .simplebar-vertical {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px !important;
  display: flex;
  justify-content: center;
}

.connect-dialog--bg {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  overflow: hidden;
}

.connect-dialog--bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.connect-dialog--tabs {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.connect-dialog--tabs-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 163px;
  max-width: 163px;
  padding: 0 0 30px 0;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  overflow: auto;
}

.connect-dialog--tabs-trigger {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s ease-in-out;
  outline: none;
}

.connect-dialog--tabs-trigger:first-child {
  padding-top: 31px;
  border-top-left-radius: 20px;
}

.connect-dialog--tabs-trigger:hover,
.connect-dialog--tabs-trigger[data-state='active'] {
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.connect-dialog--tabs-trigger:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.connect-dialog--tabs-content {
  max-width: 100%;
  padding: 31px 36px 27px 36px;
  outline: none;
}

.connect-wallet--group {
  margin-bottom: 32px;
}

.connect-wallet--group-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 56px;
}

.connect-wallet--group:last-child {
  margin-bottom: 0;
}

.connect-wallet--group-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: -6px;
  padding: 6px 10px 6px 6px;
  background: transparent;
  border: none;
  border-radius: 100px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  transition: background 0.3s ease-in-out;
}

.connect-wallet--group-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.connect-wallet--group-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.connect-wallet--group-heading {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
}

.connect-dialog--viewport {
  max-width: 457px;
}

@media screen and (max-width: 768px) {
  .connect-dialog--overlay {
    padding: 0;
  }

  .connect-dialog {
    position: fixed;
    top: 25vh;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    max-width: none;
    max-height: calc(100svh - 25vh);
    border-radius: 32px 32px 0 0;
    z-index: 100;
  }

  .connect-dialog * {
    touch-action: pan-x;
  }

  .connect-dialog .simplebar-track.simplebar-vertical {
    max-width: none;
    margin: 0 8px 0 0;
  }

  .connect-dialog--viewport {
    max-width: 100%;
    max-height: calc(100% - 123px);
  }

  .connect-dialog--viewport * {
    touch-action: pan-y pan-x;
  }

  .connect-dialog--tabs {
    flex-direction: column;
  }

  .connect-dialog--tabs-list {
    flex-direction: row;
    flex-grow: 0;
    gap: 6px;
    margin-bottom: 24px;
    padding: 29px 26px 5px 26px;
    width: 100%;
    max-width: none;
    height: auto;
    border-left: none;
  }

  .connect-dialog--tabs-trigger {
    padding: 8px 12px !important;
    border-radius: 100px !important;
    background: rgba(255, 255, 255, 0.05);
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #a7a3c7;
  }

  .connect-dialog--tabs-trigger[data-state='active'] {
    background: linear-gradient(104.03deg, #d0a0ff 4.58%, #8166ea 98.11%);
    color: #fff;
  }

  .connect-dialog--tabs-content {
    padding: 0 26px;
  }

  .connect-wallet--group-grid {
    column-gap: 35px;
  }
}

@media screen and (max-width: 370px) {
  .connect-wallet--group-grid {
    grid-template-columns: 1fr;
  }
}
