.quest-toast {
  position: relative;
  min-width: 381px;
  padding: 12px 20px;
  background: linear-gradient(94.97deg, #2A2052 11.26%, #18123E 103.72%);
  border-radius: 0 12px 12px 0;
}

.quest-toast::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(180deg, #E5C4FF 0%, #6D50E0 100%);
}

.quest-toast--heading {
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: linear-gradient(90deg, #E5C4FF 0%, #B2A6FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quest-toast--text {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
}

.quest-toast--referral-label {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, .4);
}

.quest-toast--referral-value {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.quest-toast--referral-value img {
  min-width: 18px;
  width: 18px;
  height: 18px;
}

.quest-toast--referral-value-copy {
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
}
