.retro-points-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 630px;
  padding: 96px 73px 64px 73px;
  margin: auto;
  background-image: url(./bg.png) !important;
  background-color: #0A061A;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  border-radius: 32px;
  z-index: 60;
  outline: none;
}

.retro-points-dialog::before {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid #fff;
  mask: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.08) 100%);
  border-radius: inherit;
  pointer-events: none;
  z-index: 1;
}

.retro-points-dialog--overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 50;
}

.retro-points-dialog--badge {
  margin-bottom: 32px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-align: center;
}

.retro-points-dialog--heading {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.retro-points-dialog--heading br {
  display: none;
}

.retro-points-dialog--text {
  max-width: 329px;
  margin: 0 auto 32px auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

.retro-points-dialog--action {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  max-width: 226px;
  height: 50px;
  width: 100% !important;
  margin: 0 auto;
  color: #fff !important;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .retro-points-dialog {
    padding: 70px 20px 48px 20px;
    width: calc(100% - 40px);
  }

  .retro-points-dialog--heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }

  .retro-points-dialog--heading br {
    display: unset;
  }
}
