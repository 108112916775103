.send-advanced-form-error {
  min-height: 0;
}

.send-advanced-form .statusSubtitle {
  max-width: none;
}

.send-advanced-form .transactions-list {
  max-width: 461px;
}
