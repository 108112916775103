.send-advanced-form {
  position: relative;
  width: 100vw;
  height: auto;
  border-radius: 20px;
  margin-top: 0 !important;
  max-height: none !important;
  transition-delay: 0s !important;
}

.send-advanced-form::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 1px solid #fff;

  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
}

@media screen and (max-width: 768px) {
  .send-advanced-form::before {
    border-bottom: none;
  }
}

.send-advanced-progress-form {
  max-width: 746px !important;
  min-height: 560px;
}

.send-advanced-form .transactionInfoTableDiv {
  max-width: 523px;
}

.send-advanced-form .transfer-btn-group {
  max-width: 305px;
}

.infoDiv-label {
  margin-right: auto;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #b5b2c9;
}
