.advanced-token-from-input-wrapper {
  width: 143px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 32px;
}

.advanced-token-from-input-wrapper p {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff99;
}

.advanced-token-from-input {
  display: flex;
  background-color: #ffffff0d;
  border-radius: 0px 12px 12px 0px;
  align-items: center;
  padding: 0 20px;
  height: 54px;
  width: 100%;
}

.advanced-token-from-input p {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 4px;
}

@media (max-width: 746px) {
  .advanced-token-from-input-wrapper {
    width: 100%;
    margin-right: 0;
  }

  .advanced-token-from-input {
    border-radius: 12px;
  }
}
