.container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 16px;
  min-width: 474px;
  width: 100%;
  margin: 0 auto 8px auto;
  padding: 6px;
  background: #3F337633;
}

.container._2 {
  width: 100%;
}

.container::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  pointer-events: none;
  border: 1px solid #fff;
  mask: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 729.63%);
}

.container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(94.33deg, #9F7AEC 7.8%, #775DFF 103.43%);
  border-radius: 12px;
  box-shadow: 0px 0px 12.1px 0px #FFFFFF80 inset;
  margin: 6px;
  pointer-events: none;
  transition: 0.3s ease-in-out;
}

.container._1::after {
  transform: translateX(0);
}

.container._2::after {
  transform: translateX(calc(100% - 12px));
}

.switchItem {
  position: relative;
  height: 42px;
  border-radius: 12px;
  background: transparent;
  border: none;

  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.3s ease-in-out, font-weight .3s ease-in-out;
  z-index: 1;
  cursor: pointer;
}

.container._1 .switchItem:nth-child(1),
.container._2 .switchItem:nth-child(2) {
  color: #fff;
}

@media screen and (max-width: 486px) {
  .container {
    min-width: 100%;
  }

  .switchItem {
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
  }
}
