.footerBtn {
  border-radius: 10px;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}

.infoBlock {
  background-color: #ffffff10;
  border-radius: 15px;
  overflow: hidden;
  transition: height 300ms ease-out;
}

.walletDisconnectModalTopText {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #a39dc7;
}

.walletDisconnectModal__account-wrapper {
  justify-content: space-between;
}

@media (max-width: 610px) {
  .footer-faq {
    display: none !important;
  }
}

@media (max-width: 480px) {

  .walletDisconnectModalTopText,
  .walletDisconnectModalBottomText span {
    /* padding: 0 !important;
    margin: 0 !important; */

    /* text-wrap: nowrap; */
    white-space: nowrap;
  }

  .walletDisconnectModalTopText,
  .walletDisconnectModalBottomText {
    padding: 0 !important;
    margin: 0 !important;

    white-space: nowrap;
  }
}

.walletDisconnectModalBottomText {
  font-weight: 700;
  color: white;
}

.walletDisconnectModalDivider {
  background-color: #b5b2c938;
  height: 1px;
  margin: 14px 0;
}

.walletDisconnectModalDangerText {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ff9eb6;
}

.powerIconSize {
  width: 14px;
  height: 14px;
  margin-right: 13px;
}

.power-icon-display {
  display: block;
}

.power-icon-white-display {
  display: none;
}

.walletDisconnectModalDangerDiv {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding-right: 20px;
  padding-left: 20px;
  padding-block: 5px;
  cursor: pointer;
}

.walletDisconnectModal {
  width: 200px;
  height: 130px;
  padding: 20px 0;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
  display: block;
  transition: all 0.5s ease;
  z-index: 100;
  backdrop-filter: blur(25px);
}

.walletConnectModal {
  width: 200px;
  min-width: 200px;
  height: 110px;
  min-height: 110px;
  padding: 20px 16px 20px 16px;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease;
  z-index: 100;
}

.walletConnectModalText {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: start;
  color: white;
}

.walletDisconnectModalImg {
  width: 28px;
  height: 28px;
  /* margin-right:12px ; */
  border-radius: 40px;
  background-color: white;
}

.walletDisconnectModalHide {
  width: 100%;
  height: 130px;
  padding-top: 20px;
  padding-left: 16px;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: 30px;
  left: 0;
  opacity: 0;
  background-color: #ffffff20;
  transition: all 0.5s ease;
  display: none;
}

.dropContainerDiv {
  position: absolute;
  top: 110%;
  width: 100%;
  height: 0px;
}

.topReceiveBlock {
  padding: 12px 17px;
  align-items: center;
  margin-bottom: 8px;
}

.receiveBlock {
  padding: 16px 17px 16px 17px;
  margin-bottom: 24px;
}

.receiveUsdAmount {
  margin-right: 8px;
}

.receiveShortBlock {
  font-size: 12px;
  text-align: end;
}

.receiveShortBlock p {
  margin: 0;
}

.infoSeparator {
  height: 36px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 16px;
}

.activeWalletsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}

.activeWalletsGridItem0 {
  grid-area: 1 / 1 / 2 / 3;
}

.activeWalletsGridItem1 {
  grid-area: 2 / 1 / 3 / 2;
}

.activeWalletsGridItem2 {
  grid-area: 2 / 2 / 3 / 3;
}

.activeWalletsGridItem3 {
  grid-area: 3 / 1 / 4 / 2;
}

.activeWalletsGridItem4 {
  grid-area: 3 / 2 / 4 / 3;
}

.activeWalletsGrid .disabledSoon {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.05);

  -webkit-user-select: none;
  user-select: none;
}

.activeWalletsGrid .disabledSoon span {
  color: rgba(255, 255, 255, 0.4);
}

.starknetWallets {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

@media only screen and (max-width: 480px) {
  .walletDisconnectModal {
    /* background-image: linear-gradient(#ffffff10, #ffffff10);
    backdrop-filter: blur(5px); */
    background: linear-gradient(180deg, #423591 0%, #201565 100%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    border: 0.5px solid rgba(255, 255, 255, 0.5);
  }

  .walletDisconnectModalBottomText {
    font-size: 12px;
  }

  .power-icon-display {
    display: none;
  }

  .power-icon-white-display {
    display: block;
  }

  .activeWalletsGrid {
    display: flex;
    flex-direction: column;
  }

  .starknetWallets {
    flex-direction: column;
    gap: 0;
  }

  .disabledSoon {
    display: none !important;
  }
}

.absoluteCenter {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.toggleInfoBtn {
  border: none;
  background-color: transparent;
  outline: none;
}

.header--coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 22px;
  border-radius: 16px;
  padding: 2px 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff !important;
}

.info-block-nft-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  padding: 0 8px;
  border-radius: 100px;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  color: #fff;
}

.info-block-nft-badge.late {
  background: linear-gradient(90deg, #BB3F55 0%, #E9AB88 100%);
}

@media screen and (max-width: 480px) {
  .info-block-nft-badge {
    font-size: 7px;
  }
}

.info-block-compression-row {
  margin-top: 16px;
  padding-top: 12px;
  border-top: 0.5px solid #6B659280;
}

.info-block-compression-label {
  min-width: 80px;
}

.competitors-group {
  width: 38px;
  margin-left: 4px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.competitors-group img {
  min-width: 18px;
  width: 18px;
  height: 18px;
  object-fit: contain;
  box-shadow: 1px 0px 4px 0px #00000040;
  border-radius: 100px;
}

.competitors-group img:nth-child(2) {
  transform: translateX(calc(8px));
}

.competitors-group img:nth-child(3) {
  transform: translateX(calc(16px));
}
