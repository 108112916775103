:root {
    /* --- COLORS --- */
    --cl-w: 255,255,255;
    --cl-accent: 163,157,199;
    --cl-b: 0,0,0; 
    /* --- SPACING --- */
    --root-content-px: 56px;
    --root-body-px: 42px;
    /* --- OTHER --- */
    --dev-api-dash-min-h: 546px;
}