.advanced-network-select-trigger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 50px;
  padding: 0 12px;
  background: #FFFFFF0D;
  border: none;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #fff;
}

.advanced-network-select-trigger-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.advanced-network-select-trigger-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 14px;
  width: 14px;
  height: 14px;
  margin-right: -4px;
  background: #fff;
  border-radius: 100px;
}

.advanced-network-select-trigger-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 11.2px;
  width: 11.2px;
  height: 11.2px;
  object-fit: contain;
}

.advanced-network-select-trigger-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: auto;
}

.advanced-network-select-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 290px;
  height: 413px;
  z-index: 10000;
  padding: 16px;
  background: linear-gradient(181.05deg, #45397f 0.81%, #1d183e 82.67%);
  backdrop-filter: blur(120px);
  border-radius: 16px;
  color: #fff;
  outline: none;
}

.from.advanced-network-select-menu {
  width: var(--radix-popover-trigger-width);
}

.advanced-network-select-menu::before {
  content: "";
  position: absolute;
  inset: 0;
  border: 1px solid;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.02) 100%
  );
  z-index: 1;
  pointer-events: none;
  border-radius: inherit;
}

.advanced-network-select-search {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 42px;
  padding: 0 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  cursor: text;
}

.advanced-network-select-search-icon {
  min-width: 14px;
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.advanced-network-select-search input {
  flex-grow: 1;
  height: auto;
  background: transparent;
  padding: 0;
  border-radius: 0;
}

.advanced-network-select-search input::placeholder {
  color: #a39dc7;
}

.advanced-network-select-mode {
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}

.advanced-network-select-menu-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.advanced-network-select-menu-category {
  margin-top: 12px;
  color: #a39dc7;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.advanced-network-select-menu-category:first-of-type {
  margin-top: 0;
}

.advanced-network-select-menu .simplebar-scrollbar::before {
  background-color: #807fb9 !important;
  opacity: 1 !important;
  width: 4px !important;
  top: 4px !important;
  bottom: 4px !important;
  left: 50%;
  transform: translateX(-50%);
}

.advanced-network-select-menu .simplebar-vertical {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px !important;
  display: flex;
  justify-content: center;
  width: 8px !important;
}

.advanced-network-select-menu-item {
  background: transparent;
  border: none;
  padding: 0;
  color: #fff;
}
