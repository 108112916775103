.select-receiving-label-title {
  font-weight: 700;
  font-size: 14px;
}

.select-receiving {
  display: grid;
  gap: 8px;
  grid-template-columns: auto 110px 143px 24px;
}

.select-receiving-mobile-header {
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-receiving-mobile-header p {
  margin: 0;
  padding: 0;
  color: #ffffff99;
  font-weight: 500;
  font-size: 12px;
}

.select-receiving--disabled {
  grid-template-columns: 345px 110px auto;
}

.select-receiving-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #b5b2c9;
}

.select-receiving-input {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 50px;
  padding: 15px 12px 15px 16px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  background: #FFFFFF0D;
  cursor: text;
}

.select-receiving-input--disabled {
  cursor: default !important;
}

.select-receiving-input input {
  flex-grow: 1;
  max-width: 100%;
  height: auto;
  padding: 0;
  background: transparent;
  border-radius: 0;
  font-size: 12px;
}

.select-receiving-input--empty input:focus {
  padding-left: 22px;
}

.select-receiving-input input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  transition: transform 0s, color 0.3s;
}

.select-receiving-input.select-receiving-input--empty input:focus::placeholder {
  transform: translateX(-22px);
}

.select-receiving-input--disabled input {
  cursor: default !important;
}

.select-receiving .first-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select-receiving .second-input {
  border-radius: 0;
}

.select-receiving .third-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select-receiving--delete {
  min-width: 24px;
  width: 24px;
  padding: 0;
  background: transparent;
  border: none;
}

.select-receiving-bottom {
  margin-top: 16px;
  margin-bottom: 24px;
}

.select-receiving--add {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  border: none;
  background: linear-gradient(90deg, #e5c4ff 0%, #b3a6ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;

  transition: opacity 0.3s ease-in-out;
}

.select-receiving--add:disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 746px) {
  .select-receiving-wrapper {
    border-top: 1px solid #2f2855;
  }

  .select-receiving {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    height: fit-content;
  }

  .select-receiving--disabled {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    height: fit-content;
  }

  .select-receiving-label {
    display: none;
  }

  .select-receiving-input {
    grid-column: 1 / -1;
  }

  .select-receiving .first-input {
    grid-column: 1 / -1;
    border-radius: 12px 12px 0 0;
  }

  .select-receiving .second-input {
    grid-column: 1 / 2;
    border-radius: 0 0 0 12px;
  }

  .select-receiving .third-input {
    grid-column: 2 / -1;
    border-radius: 0 0 12px 0;
  }

  .select-receiving--delete {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}
