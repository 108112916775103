.network-input {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px 12px 0 0;
}

.network-input--value {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.network-input--image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: 22px;
  width: 22px;
  max-width: 22px;
  min-height: 22px;
  height: 22px;
  max-height: 22px;
  border: none;
  border-radius: 100px;
  background: #fff;
}

.network-input--image:disabled {
  filter: grayscale(100%);
  pointer-events: none;
}

.network-input--image img {
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.network-input--fast-select {
  display: flex;
  align-content: center;
  margin-left: auto;
  margin-right: 5px;
  width: 94px;
}

.network-input--fast-select .network-input--image {
  box-shadow: 4px 0px 4px 0px #00000040;
  cursor: pointer;
}

.network-input--action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 4px;
  margin: -8px -8px -8px -4px;
  background: transparent;
  border: none;
}

.network-dialog {
  position: relative;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 474px;
  min-height: 613px;
  padding: 24px 30px 39px 30px;

  background-repeat: no-repeat !important;
  background-color: #0e0e25 !important;
  background-image: url(@/Assets/images/send-bg-large.svg) !important;
  background-size: 100% 377px !important;
  border-radius: 24px;
  color: #fff;
  z-index: 10;
}

.network-dialog--overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  padding-right: var(--removed-body-scroll-bar-size);
  overflow: auto;
}

.network-dialog::before {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid #fff;
  border-radius: inherit;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  pointer-events: none;
}

.network-dialog--viewport {
  max-height: 386px;
  height: 100%;
  padding-right: 10px;
}

.network-dialog .simplebar-scrollbar::before {
  background-color: #807fb9 !important;
  opacity: 1 !important;
  width: 4px !important;
  top: 2px !important;
  bottom: 2px !important;
  left: 50%;
  transform: translateX(-50%);
}

.network-dialog .simplebar-vertical {
  width: 8px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px !important;
  display: flex;
  justify-content: center;
}

.network-dialog--heading {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
}

.network-dialog--search {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  cursor: text;
}

.network-dialog--search input {
  height: auto;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.network-dialog--search input::placeholder {
  color: #a39dc7;
}

.network-dialog--search input:focus::placeholder {
  color: #a39dc7;
}

.network-dialog--category {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 10px 14px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #a7a3c7;
  transition: color 0.3s ease-in-out;
}

.network-dialog--category::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(100.98deg, #f0c4ff 4.83%, #8f76f6 92.08%);
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.network-dialog--category.active {
  color: #fff;
}

.network-dialog--category.active::before {
  opacity: 1;
}

.network-dialog .dropdown-item {
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.network-dialog .dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}

@media screen and (max-width: 480px) {
  .network-dialog {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 480px;
    min-height: 0;
    height: 606px;
    max-height: 100vh;
    overflow: auto;
    padding: 24px;
    border-radius: 24px 24px 0 0;
  }

  .network-dialog--viewport {
    max-height: calc(100% - 165px);
  }
}
