.fillInDialog-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: linear-gradient(90deg, #e5c4ff 0%, #b3a6ff 100%);
  border: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}

.fillInDialog-modal {
  width: 100vw;
  max-width: 746px;
}

.fillInDialog-modalBody {
  position: relative;
  border-radius: 28px;
  padding: 57px 48px 69px 52px;
  background-color: #1e183f !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background-position: 0px -142px !important;
  background: linear-gradient(180deg, #8d7bff5e 0%, #1e183f 100%);
  background-image: url(@/Assets/images/send-bg-large.svg) !important;
}

@media (max-width: 746px) {
  .fillInDialog-modalBody {
    padding: 40px 20px;
    border-radius: 28px 28px 0 0;
  }
}

.fillInDialog-modalBody::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 1px solid #fff;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  z-index: 1;
  pointer-events: none;
}

.fillInDialog-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  color: #ffffff;
}

.fillInDialog-textarea-container {
  width: 100%;
  min-height: 173px;
  max-height: 173px;
  margin-top: 8px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.fillInDialog-textarea-container .simplebar-scrollbar::before {
  background-color: #807fb9 !important;
  opacity: 1 !important;
  inset: 4px !important;
}

.fillInDialog-textarea-container .simplebar-vertical,
.fillInDialog-textarea-container .simplebar-horizontal {
  display: flex;
  justify-content: center;
}

.fillInDialog-textarea-container .simplebar-vertical {
  margin-top: 11px;
  margin-bottom: 11px;
}

.fillInDialog-textarea-container .simplebar-horizontal {
  margin-left: 11px;
  margin-right: 11px;
}

.fillInDialog-textarea {
  display: grid;
  grid-template-columns: minmax(10px, auto) 1fr;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-right: 12px;
  cursor: text;
}

.fillInDialog-textarea-line-number {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  color: #fff;
}

.fillInDialog-textarea-content {
  display: block;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  background: transparent;
  color: #fff;
  outline: none;
  border: none;
  resize: none;
  white-space: nowrap;
  overflow: hidden;
}

.fillInDialog-textarea-content::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.fillInDialog-textarea-content:focus::placeholder {
  opacity: 0;
}

.fillInDialog-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 122px;
  max-width: 302px;
  margin: 0 auto;
}

.fillInDialog-actions .btnGradient {
  width: 100%;
  height: 50px;
}

.fillInDialog-cancel {
  padding: 0;
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  color: #ffffff;
}

.fillInDialog-error-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
  color: #ff9eb6;
}

.fillInDialog-error-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.fillInDialog-error {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #ff9eb626;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.fillInDialog-error span {
  display: -webkit-box;
  margin: 0 2px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.fillInDialog-token-change-wrapper {
  width: fit-content;
}

.fillInDialog-token-change {
  margin-bottom: 24px;
}
