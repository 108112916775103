.arrowDownIcon {
  width: 8px;
  height: 12px;
  border-radius: 15px;
}

.transfer-expected-brigde-time {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 27px;
}

.transfer-expected-brigde-time span:first-child {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff99;
}

.transfer-expected-brigde-time span:last-child {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.transferInfoWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 42px;
}

.transferInfoContent {
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  width: 354px;
  box-sizing: border-box;
}

.transactionAwaitingLoaderWrapper {
  width: 100%;
  max-height: 75px;
  display: flex;
  position: relative;
}

.transactionAwaitingLoader {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 38px;
}

.loadingCirclesWrapper {
  height: 100%;
  margin-top: 40px;
}

.transferInfoItem {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.transactionConfirmationDescription {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #d1cbfa;
  max-width: 212px;
  margin: 0 auto;
  margin-bottom: 38px;
}

.transactionAwaitingLoader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.transactionAwaitingLoader img {
  display: flex;
  justify-content: center;
  animation: spin 1000ms linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.delayMessageWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
}

.delayMessage {
  background: #c6764826;
  border: 1px solid #ffc3a136;
  border-radius: 12px;
  padding: 12px 16px 12px 16px;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
}

.delayTitle {
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  background: linear-gradient(90.34deg, #f8cbab 2.18%, #df8569 102%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 4px;
}

.delayDescription {
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #b8988c;
}

.secondarytext {
  color: #a39dc7;
}

.circle {
  width: 34px;
  height: 34px;
  border-radius: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: -14px;

  background-color: #5d4f9d;
  position: relative;
}

.transferInfoSubtitle {
  font-size: 14px;
  font-weight: "700";
  margin-bottom: 8px;
}

.textAlignEnd {
  text-align: end;
}

.backBtn {
  background: transparent;
  border: none;
  text-decoration: none;
  color: white;
  font-weight: 700;
}

.btnInfoGradient {
  background-image: linear-gradient(
    90deg,
    #fff0fe99,
    #8f7cff99,
    #8f7cff99,
    #fff0fe99
  );
  border-radius: 2rem;
  color: white;
  height: 3rem;
  width: 3rem;
}

.progressStatusText {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 4px;
}

.progressStatusText-regular {
  font-weight: 400 !important;
  margin-bottom: 24px !important;
}

.progressContainer {
  min-height: 264px;
  border-radius: 15px;
  display: flex;
  gap: 16px;
  padding-left: 26px;
  padding-top: 27px;
  padding-right: 23px;
  background: linear-gradient(
    180deg,
    rgba(223, 218, 255, 0.1) 0%,
    rgba(138, 121, 244, 0.1) 100%
  );
}

@media (max-width: 746px) {
  .progressContainer {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.progressDepositSending {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto auto;
  gap: 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.progressDepositSending-loader {
  position: relative;
  width: 70px;
  height: 70px;
}

.progressLeftDiv {
  min-width: 61px;
  width: 61px;
  height: 100%;
  max-height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
}

.subtitle {
  font-weight: 400;
}

.mTop40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.progressCircle {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #615e79;
}

.check {
  width: 5px;
  height: 4px;
}

:root {
  --active-circle-radius: 13px;
  --active-inner-circle-radius: 8px;

  --progress-bar-height: 150px;
}

.progress {
  min-width: 1px;
  width: 1px;
  height: var(--progress-bar-height);
  background-image: linear-gradient(#9b73ff, #615e79);
}

.progress.inactive {
  background: #615e79;
}

.activeProgressCircle {
  background-image: linear-gradient(#8282fc, #a46cff);
  width: 16px;
  min-height: 16px;
  height: 16px;
  border-radius: 8px;
}

.middleCheckIcon {
  position: absolute;
  align-self: center;
  top: calc(var(--progress-bar-height) * 0.58);
}

.progressAbsolute {
  position: absolute;
  align-self: center;
  transition: all 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.activeBoxShadow {
  box-shadow: 0px 0px 20px #8282fc, 0px 0px 30px #a46cff;
}

.transY0 {
  transition: all 1s ease;
  transform: translateY(-12px);
}

.trans50 {
  transition: all 1s ease;
  transform: translateY(75px);
}

.transY100 {
  transition: all 1s ease;
  transform: translateY(136px);
}

.textBlack {
  color: #615e79;
}

.textSecond {
  color: #9d98be;
}

.formContainer {
  width: 474px;
  min-height: 200px;
  margin-bottom: 98px;
}

.padding-bottom {
  padding-bottom: 3rem;
}

.justify-content-center-container {
  justify-content: center;
}

.arrow {
  position: absolute;
  border-radius: 50%;
  background-image: linear-gradient(#8282fc, #a46cff);
  width: 16px;
  height: 16px;
  margin: auto;
}

.withDelay .arrow {
  background: #ffb885;
}

.ring {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: scale(0);
  animation: pulsing 4s ease-out infinite;
}

.transfer-confirmation-text {
  background: -webkit-linear-gradient(45deg, #a293ff, #d1c9ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ring.ring-1 {
  background-image: linear-gradient(#8282fc50, #a46cff50);
}

.withDelay .ring.ring-1 {
  background: rgba(204, 150, 110, 0.5);
}

.ring.ring-2 {
  animation-delay: 1000ms;
  background-image: linear-gradient(#8282fc50, #a46cff50);
}

.withDelay .ring.ring-2 {
  background: rgba(204, 150, 110, 0.5);
}

.ring.ring-3 {
  animation-delay: 2000ms;
  background-image: linear-gradient(#8282fc50, #a46cff50);
}

.withDelay .ring.ring-3 {
  background: rgba(204, 150, 110, 0.5);
}

.ring.ring-4 {
  animation-delay: 3000ms;
  background-image: linear-gradient(#8282fc50, #a46cff50);
}

.withDelay .ring.ring-4 {
  background: rgba(204, 150, 110, 0.5);
}

.progress-page-footer-container {
  width: 100%;
}

.status-form-mt {
  margin-top: 131px;
}

.withDelay.text-gradient {
  background-image: linear-gradient(90.34deg, #f8cbab 2.18%, #df8569 102%);
}

.withDelay.subtitle {
  color: #b5b2c9;
}

.progressActionWrapper {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.progressActionWrapper .btn {
  height: 50px;
  gap: 8px;
}

.progressAction {
  display: flex;
  align-items: center;
  gap: 8px;

  border: none;
  background: linear-gradient(270deg, #e5c4ff -4.46%, #9381ff 88.85%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-decoration: none !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.progressTransactionLink {
  min-width: 18px;
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

@keyframes pulsing {
  0% {
    /* box-shadow: 0px 0px 6px #8282fc; */
    transform: scale(0);
    opacity: 0;
  }

  50% {
    /* box-shadow: 0px 0px 6px #8282fcaf; */
    opacity: 1;
  }

  85% {
    /* box-shadow: 0px 0px 6px #8282fc5e; */
  }

  90% {
    /* box-shadow: 0px 0px 6px #8282fc28; */
    transform: scale(1.3);
    opacity: 0;
  }

  100% {
    /* box-shadow: 0px 0px 6px #8282fc28; */
    transform: scale(1.3);
    opacity: 0;
  }
}

@media only screen and (max-width: 480px) {
  .formContainer {
    width: 100%;
    height: auto;
    margin-bottom: 84px;
  }

  .status-form-mt {
    margin-top: 103px;
  }

  .progressStatusText-regular {
    margin-bottom: 32px !important;
  }

  .justify-content-center-container {
    justify-content: unset;
  }

  .transferInfoWrapper__loading {
    margin-bottom: 230px;
  }
}
