.unsupported-chain-dialog--overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, .8);
  z-index: 100;
}

.unsupported-chain-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 552px;
  margin: auto;
  padding: 64px 28px 28px 28px;
  background: #0E0922;
  border-radius: 20px;
  outline: none;
}

.unsupported-chain-dialog::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 1px solid #D8D1FF24;
  pointer-events: none;
  z-index: 1;
}

.unsupported-chain-dialog--bg {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  overflow: hidden;
}

.unsupported-chain-dialog--bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.unsupported-chain-dialog--close {
  position: absolute;
  top: 18px;
  right: 18px;
  background: transparent;
  border: none;
  z-index: 10;
}

.unsupported-chain-dialog--icon {
  position: relative;
  height: 37px;
  margin-bottom: 18px;
}

.unsupported-chain-dialog--heading {
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.unsupported-chain-dialog--text {
  max-width: 301px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #D8D1FFB2;
}

.unsupported-chain-dialog--hint {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #D8D1FF80;
}

.unsupported-chain-dialog--action {
  width: 100%;
  max-width: 254px;
  height: 50px;
}
