.notifications-menu--trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  padding: 0;
  color: #fff;
}

.notifications-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 360px;
  max-height: 100%;
  transform-origin: 50% 0%;
  z-index: 100;
  outline: none;
}

.notifications-menu-top {
  display: flex;
  flex-direction: column;
  min-height: 195px;
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px 20px 0 0;
  backdrop-filter: blur(50px);
  box-shadow: -7px 16px 60px 0px #150c3699;
  transform-origin: center bottom;
}

.notifications-menu-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 9px 20px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0 0 20px 20px;
  backdrop-filter: blur(50px);
  box-shadow: -7px 16px 60px 0px #150c3699;
  transform-origin: center top;
}

.notifications-menu-body.disabled {
  background: url('../../Assets/images/sidebar-placeholder-bg.png');
  background-size: cover;
  height: 424px;
  width: 360px;
}

.notifications-menu-top::before,
.notifications-menu-body::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 1px solid #fff;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  z-index: -1;
  pointer-events: none;
}

.notifications-menu--top::before {
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.16) 100%
  );
}

.notifications-menu-body .simplebar-track.simplebar-vertical {
  margin-top: 31px;
  transform: translateX(50%);
}

.notifications-menu-body
  .simplebar-track.simplebar-vertical
  .simplebar-scrollbar:before {
  background: linear-gradient(180deg, #9e86ff, #e1c9ff);
}

.notifications-menu--close {
  position: absolute;
  top: 24px;
  right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: transparent;
  border: none;
  z-index: 10;
  padding: 0;
}

.notifications-menu--tabs {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  margin-bottom: 18px;
}

.notifications-menu--tabs::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  border-radius: 36px;
  background: rgba(255, 255, 255, 0.1);
}

.notifications-menu--tabs::after {
  content: '';
  position: absolute;
  left: 0;
  width: 50%;
  bottom: 0;
  height: 2px;
  border-radius: 36px;
  background: linear-gradient(90deg, #9e86ff 0%, #e1c9ff 100%);
  transition: 0.3s ease-in-out;
}

.notifications-menu--tabs._02::after {
  transform: translateX(100%);
}

.notifications-menu--tabs-trigger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 0 12px 16px 12px;
  background: transparent;
  border: none;
  border-radius: 0;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  color: rgba(137, 132, 172, 0.6);
}

.notifications-menu--tabs-trigger[data-state='active'] {
  background: linear-gradient(296.88deg, #ead0ff 17.62%, #b297ff 90.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.notifications-menu--tabs-trigger--counter {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: linear-gradient(156.57deg, #fabdff 7.19%, #987eff 84.88%);
  border-radius: 100px;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
}

.notifications-menu--tabs-trigger[data-state='active']
  .notifications-menu--tabs-trigger--counter {
  background: rgba(255, 255, 255, 0.2);
}

.notifications-menu--tabs-content {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.notifications-menu--group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.notifications-menu--group-heading {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.notifications-menu--footer {
  width: 100%;
  padding: 12px 0 8px 0;
  margin-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.notifications-menu--footer--progress-title {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.notifications-menu--footer--progress-title-right {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.notifications-menu--footer--progress-bar {
  margin-top: 14px;
  margin-bottom: 8px;
}

.notifications-menu--footer--reward {
  display: flex;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: rgba(149, 140, 187, 1);
}

.notifications-menu--footer--reward span {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .notifications-menu-top,
  .notifications-menu-body {
    max-width: calc(100vw - 36px);
    background: #15084963;
  }
}

.notifications-menu--task {
  position: relative;
  padding: 10px 12px;
  height: 90px;
  width: 320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  justify-content: start;
  align-items: center;
  overflow: hidden;
}

.notifications-menu--task--content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  width: 232px;
}

.notifications-menu--task--heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 2px;
}

.notifications-menu--task--heading span {
  white-space: normal; /* Prevent text wrapping if you need to keep text on one line */
  flex-grow: 0; /* Prevent spans from stretching */
  flex-shrink: 0; /* Prevent text from shrinking */
}

.notifications-menu--task--heading span img {
  margin: 0 3px;
}

.notifications-menu--task--rewards {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 2px;
}

.notifications-menu--task--rewards--item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  gap: 2px;
  margin-top: 4px;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  min-width: 0;
}

.notifications-menu--task--action {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  background: rgba(215, 205, 255, 0.1);
  outline: none;
  border: none;
  z-index: 100;
}

.notifications-menu--task--coins-img {
  position: absolute;
  top: 0;
  right: 9px;
}

.notifications-menu--task--capsule-img {
  position: absolute;
  top: 0px;
  left: 205px;
}

@media screen and (max-width: 768px) {
  .notifications-menu--task--heading br {
    display: none;
  }
}

.rotate-animation {
  animation: rotate 1s ease-in-out;
  cursor: pointer;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
