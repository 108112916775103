.footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 4px;
}

.footer-nav {
  display: flex;
  align-items: center;
  gap: 32px;
}

.footer-nav-item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #B5B2C9 !important;
  text-decoration: none !important;
}

.footer-audits {
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  height: max-content;
  gap: 16px;
}

.main-page .footer-audits {
  padding-top: 20px;
  padding-bottom: 4px;
}

.footer-audits-label {
  position: absolute;
  left: 50%;
  transform: translate(-50%, calc(-100% - 4px));
  font-size: 9px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.11em;
  text-align: center;
  text-transform: uppercase;
  color: #908CA7;
}

.footer-audits-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 4px;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #B5B2C9 !important;
  text-decoration: none !important;
}

.footer-audits-item img {
  height: 17px;
}

.footer-audits-item:nth-child(1) {
  gap: 10px;
}

.footer-audits-menu--trigger {
  display: none;
}

.footer-audits-menu--trigger img {
  width: 18px;
  height: 18px;
}

.footer-audits-menu--trigger[data-state="open"] img {
  transform: rotate(180deg);
}

.footer-audits-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 10;
}

.footer-audits-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 164px;
  min-height: 44px;
  padding: 6px 0;
  border: .5px solid rgba(255, 255, 255, .2);
  border-radius: 8px;
  background: rgba(255, 255, 255, .1);
  backdrop-filter: blur(40px);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #B5B2C9;
  text-decoration: none;
}

.footer-audits-menu-item img {
  height: 17px;
}

.footer-socials {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  margin-right: -16px;
}

.footer-socials-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
}

@media screen and (max-width: 1000px) {
  .footer-socials {
    gap: 0;
  }
}

@media screen and (max-width: 992px) {
  .footer {
    display: flex;
  }

  .footer-nav {
    justify-content: space-between;
    flex-grow: 1;
    gap: 0;
  }

  .footer-audits-menu--trigger {
    display: flex;
  }

  .footer-audits {
    display: none;
  }

  .footer-socials {
    display: none;
  }
}
