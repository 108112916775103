.header {
  display: flex;
  align-items: center;
  padding: 0 29px 0 53px;
  min-height: 73px;
}

.header-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 36px;
}

.app-header-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
}

.header-nav.ms-0 {
  gap: 32px;
}

.header-nav-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none !important;
  color: #fff !important;
}

.rari-adventure-btn {
  position: relative;
  padding: 6px 12px;
  border-radius: 40px;

  background: linear-gradient(
    90deg,
    #8e74ff 0%,
    rgba(159, 77, 255, 0.68) 41%,
    rgba(32, 12, 113, 0.99) 100%
  );

  outline: none;
}

.headerBtn.headerBtnPopover {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(153, 153, 153, 0.01) 100%
  );
  backdrop-filter: blur(20px);
  box-shadow: none;
  position: relative !important;
}

.headerBtn.headerBtnPopover:hover {
  box-shadow: none;
}

.gradient-border-mask {
  position: relative !important;
}

.headerBtn.headerBtnPopover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  border: 1px solid #fff;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ) !important;
  pointer-events: none;
}

.header-popover-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rari-adventure-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px; /* Adjust this to control the border thickness */
  border-radius: 40px;
  background: linear-gradient(
    166.35deg,
    rgba(255, 255, 255, 0.6) 4.26%,
    rgba(255, 255, 255, 0) 85.09%
  );
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.header-nav-item--divider {
  height: 100%;
  min-height: 28px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.header-nav-item.headerBtn {
  margin-left: -16px;
}

.header-nav-item--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 74px;
  height: 42px;
  margin-right: 18px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-decoration: none !important;
}

@media screen and (max-width: 1200px) {
  .header-nav {
    gap: 20px;
  }

  .app-header-nav {
    gap: 10px;
  }
}

@media screen and (max-width: 992px) {
  .header {
    padding: 23px 15px 0 5px;
  }

  .header.app-header {
    padding: 23px 15px 0 25px;
  }

  .header-nav,
  .app-header-nav {
    display: none;
  }
}
