.execution-type-wrapper {
  margin-bottom: 28px;
}

.execution-type {
  position: relative;
  width: max-content;
  height: 49px;
  border-radius: 10px;
  padding: 6px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.05);
}

.execution-type--highlight {
  --active-item: 0;
  position: absolute;
  width: 111px;
  height: 37px;
  border-radius: 8px;
  z-index: -1;
  background: linear-gradient(94.35deg, #d7b9ff -3.08%, #8f7dff 81.92%);
  transform: translateX(calc((100% + 8px) * var(--active-item)));
  transition: transform 0.3s ease-in-out;
}

._2 .execution-type--highlight {
  --active-item: 1;
}

.execution-type--trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 111px;
  height: 37px;
  padding: 0;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #b5b2c9;
  transition: 0.3s ease-in-out;
}

.execution-type--trigger.active {
  font-weight: 700;
  color: #fff;
}

@media (max-width: 746px) {
  .select-execution-type-wrapper {
    border-top: 1px solid #2f2855;
    padding-top: 24px;
  }

  .execution-type-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .execution-type {
    width: 100%;
    justify-content: space-between;
    padding: 0;
    gap: 0;
  }

  .execution-type--highlight {
    width: calc(50% - 8px);
    transform: translateX(calc((100% * var(--active-item)) + 8px));
  }

  .execution-type--trigger {
    width: 50%;
    flex-grow: 1;
  }
}
