.network_loader_circle_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  max-width: 100px;
}

.network_loader_circle_address {
  display: flex;
  align-items: center;
  gap: 8px;
}

.network_loader_circle_address p {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #a39dc7;
  margin: 0;
  padding: 0;
}

.network_loader_circle_address_action {
  display: flex;
  width: 16px;
  height: 16px;
}

.network_loader_circle_info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}

.network_loader_circle_title {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 0;
  text-align: center;
}

.network_loader_circle_amount {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;

  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;
}

.network_loader_circle_token {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 100px;
}

.network_loader_circle_token img {
  min-width: 12px;
  width: 12px;
  height: 12px;
}

.network_loader_circle_content {
  position: relative;
  padding-bottom: 20px;
}

.network_loader_circle_image {
  width: 56px;
  min-width: 56px;
  height: 56px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.network_loader_circle_image img {
  width: 42px;
  min-width: 42px;
  height: 42px;
}

.network_loader_circle_background {
  fill: none;
  stroke: #ffffff1a;
  stroke-linecap: round;
}

.network_loader_circle_progress {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.spinning {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(540deg);
  }
}

.network_loader_circle_text {
  font-family: Albert Sans;
  font-weight: 800;
  font-size: 52px;
  line-height: 62.4px;
}

.nowrap {
  text-wrap: nowrap;
}
