.App {
  text-align: center;
}

* {
  font-family: 'Satoshi', sans-serif;
}

body {
  --toastify-toast-width: 405px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo-img {
  width: 20px !important;
  height: 20px !important;
}

img {
  max-width: 100%;
}

.infoDiv {
  border-radius: 15px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  padding: 0 24px 0 16px;
  width: 100%;
}

.info-block-price-div {
  text-align: left;
  width: fit-content;
}

.progress-info-block-price-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.progress-info-block-price-div {
  text-align: left;
  width: 92px;
}

.mb-16 {
  margin-bottom: 16px;
}

.clipPath {
  clip-path: circle(34px at 0 100px);
}

.form-max-width {
  width: 100%;
  max-width: 474px;
}

.formBg {
  backdrop-filter: blur(10px);
  transition: height 0.3s ease;
  justify-content: center;
  align-items: center;
  margin-top: 81px;

  margin-bottom: 98px;

  background-repeat: no-repeat !important;
  background-color: #1e183f !important;
  background-image: url(Assets/images/send-bg-large.svg) !important;
  background-size: 100% 377px !important;
}

.statusForm {
  padding: 77px 34px;
  width: 474px;
}

.gradient-border-mask {
  position: relative !important;
}

.gradient-border-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px !important;
  border: 1px solid #fff;
  mask: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ) !important;
  pointer-events: none;
}

.defaultRadius {
  border-radius: 20px !important;
}

.headerArrow {
  height: fit-content;
  width: fit-content;
  margin-left: 10px;
  margin-top: -2px;
}

.page-background-color {
  background-color: #000000;
}

.formGradient {
  position: absolute;
  width: 100%+2px;
  bottom: -1px;
  left: -2px;
  right: -2px;
  height: 70%;
  background-image: linear-gradient(#00000000, #00000060);
}

.transitionHeight {
  transition: height 300ms ease-out;
}

.formBody {
  position: relative;
  top: 0.5px;
  box-sizing: border-box;
  padding: 6px 28px 16px 28px;
}

@media screen and (max-width: 480px) {
  .formBody {
    padding: 0;
  }
}

.progressFormBody {
  position: relative;
  top: 0.5px;
  box-sizing: border-box;
  padding: 34px 30px 28px 38px;
}

.mainSendModal {
  /* background-size: cover; */
  background: radial-gradient(ellipse at top, #6555b9, #1a1a1a);
}

.separator {
  height: 2px;
  width: 95%;
  background: radial-gradient(#ffffff1f 0%, #ffffff00 100%);
  align-self: center;
  margin: 24px 0;
}

.mobile-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 100;
  outline: none;
  overflow: auto;
}

.mobile-menu-background {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.mobile-menu-img {
  top: unset !important;
  bottom: 0 !important;
}

.mobile-menu-close {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
}

.mobile-menu-header {
  height: 50px;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 23px 18px 0 20px;
}

.mobile-menu-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-menu-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  text-align: center;
}

.mobile-menu-tabs-item {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.mobile-menu-tabs-item_flex {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.mobile-menu-tabs-item_gradient {
  background: linear-gradient(93deg, #ffc2a0 6.77%, #f5b7ff 99.03%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.mobile-menu-tabs-item:not(:first-child) {
  margin-top: 40px;
}

.mobile-menu-footer {
  margin: auto auto 45px auto;
  padding-top: 84px;
  display: flex;
}

.mobile-menu-footer-btn {
  border-radius: 6px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
}

.mobile-menu-footer-btn img {
  width: 18px;
  height: 18px;
}

.mobile-menu-footer-btn:not(:first-child) {
  margin-left: 8px;
}

.imgDiv20 {
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-top {
  margin-top: 8px;
}

.dropdown::after {
  display: none;
}

.dropdown-menu.show {
  padding-inline: 11px !important;
  padding-block: 7px !important;
  width: 211px !important;
  border-radius: 12px;
  background: linear-gradient(172.41deg, #3e3b50 -27.46%, #1e1d24 101.26%);
  box-shadow: 5px 5px 10px 0px #00000066;
  border: none;
  margin-top: 9px;
}

.transferInfoTitle {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.transferInfoSubtitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 8px;
}

.transactionInfoDableDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 746px) {
  .transactionInfoDableDiv {
    padding-right: 20px;
  }
}

.transactionInfoDableDivCircle {
  width: 24px;
  height: 24px;
  border-radius: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b07fe0;
  background-image: linear-gradient(130deg, #7c82ff, #b07fe0);
  border: none;
}

.textSecondary {
  color: #9d98be;
}

.searchIcon {
  position: absolute;
  align-self: center;
  left: 1rem;
  height: 18px;
  width: 18px;
}

.gradient-border-mask-connect-wallet {
  position: relative !important;
}

.gradient-border-mask-connect-wallet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px !important;
  border: 1px solid transparent;
  background: linear-gradient(359deg, transparent, rgba(255, 255, 255, 0.6))
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.smallCircleBtn {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 300ms ease-out;
}

.smallCircleBtnCoinIcon {
  background-color: #ffffff;
  margin-right: 10px;
}

.smallSquareBtn {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 300ms ease-out;
  background-color: #ffffff1a;
}

.smallSquareBtnCoinIcon {
  background-color: #ffffff;
  margin-right: 10px;
}

.coinImg {
  width: 16px;
  height: 16px;
}

.header-button {
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  animation: opacityIn 1s ease;
}

.header-button--flex {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.header-button--flex img {
  flex: 0 0 auto;
}

.header-button--gradient span {
  display: inline-block;
  min-width: 107px;
  font-weight: 700;
  background: linear-gradient(93deg, #ffc2a0 6.77%, #f5b7ff 99.03%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

.cursor {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.networkInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.networkInfoRightSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.footer-mt {
  margin-top: auto;
}

.networkInfoLeftDiv {
  display: flex;
  align-items: center;
}

.networkInfoLeftDiv img {
  border-radius: 50%;
}

.networkInfoNetIcon {
  width: 20px;
  height: 20px;
}

.infoText {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
}

.infoText-bold {
  font-weight: 700 !important;
}

.page-padding-bottom {
  padding-bottom: 16px !important;
}

.mainSend {
  background-size: cover;
  position: relative;
  /*background-image: radial-gradient(circle at 57% 5%, rgba(158,134,255,1) -11%, rgba(0,0,0,0) 36%),*/
  /*                  radial-gradient(circle at 34% -1%, rgba(22,19,140,1) 0%, rgba(0,0,0,0) 27%);*/

  /*background-image: radial-gradient(circle at 55% 1%, rgba(158,134,255,1) -20%, rgba(0,0,0,0) 37%),*/
  /*                   !*radial-gradient(circle at 50% 1%,  rgba(112,88,255,1) 0%, rgba(0,0,0,0) 37%), *!*/
  /*                  radial-gradient(circle at 40% -1%, rgba(22,19,140,1) 0%, rgba(0,0,0,0) 25%);*/

  /*background-image: radial-gradient(circle at 55% 1%, rgba(158,134,255,1) -29%, rgba(0,0,0,0) 45%),*/
  /*radial-gradient(circle at 35% -24%, rgba(22,19,140,1) 18%, rgba(0,0,0,0) 38%);*/
  padding-inline: 40px;
  overflow: hidden;
  z-index: 1;
}

.formModalBg {
  /*backdrop-filter: blur(10px);*/
  background-color: #0e0e25;
  transition: height 0.3s ease;
  justify-content: center;
  align-items: center;
  /*border: #ffffff40 0.6px solid;*/
  margin-top: 81px;
  margin-bottom: 98px;
}

.modal-dialog-centered {
  min-height: 100vh;
  min-height: 100svh;
}

.send-bg-img,
.mobile-menu-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  max-height: 100vh;
  pointer-events: none;
}

.statusText {
  min-width: 75px;
}

.secondary-text-weight {
  font-weight: 700;
}

.network-info-amount-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.copyIcon {
  width: 12px;
  height: 12px;
}

.header-mobile-wallet-section {
  display: flex;
  justify-content: flex-end;
  min-width: 79px;
  gap: 5px;
  height: 50px;
}

.header-mobile-wallet-account {
  position: relative;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff10;
  border-radius: 8px;
  border: 1px solid #ffffff50;
  cursor: pointer;
}

.connect-wallet-mobile-container {
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  /* height: 110px; */
  /* background-image: linear-gradient(rgba(66, 53, 144, 1), rgba(32, 21, 101, 1), rgba(255, 255, 255, 0.5)); */
  /* backdrop-filter: blur(5px); */
  background: linear-gradient(180deg, #423591 0%, #201565 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));

  border-radius: 12px;
  padding: 20px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
}

.disabled-wallets-mobile-container .connect-wallet-mobile-row,
.disabled-wallets-mobile-container .connect-wallet-mobile-row:hover {
  cursor: not-allowed;
  /* background-color: rgba(255, 255, 255, 0.05); */
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
}

.disabled-wallets-mobile-container
  .connect-wallet-mobile-row
  .connect-wallet-row-image-circle,
.disabled-wallets-mobile-container
  .connect-wallet-mobile-row
  .connect-wallet-row-image-circle:hover {
  opacity: 0.6;
  filter: grayscale(100%);
}

.disabled-wallets-mobile-container
  .connect-wallet-mobile-row
  .connect-wallet-row-text,
.disabled-wallets-mobile-container
  .connect-wallet-mobile-row
  .connect-wallet-row-text:hover {
  color: rgba(255, 255, 255, 0.5);
}

.disabled-wallets-mobile-container .disabled-message {
  font-size: 12px;
  font-weight: 500;
  color: rgba(218, 211, 255, 1);

  opacity: 0.8;
  margin-bottom: 0;
}

.connect-wallet-mobile-container .divider {
  margin: 14px 0;
  height: 1px;
  width: 100%;
  background-color: rgba(114, 108, 149, 1);
}

.connect-wallet-mobile-row {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.connect-wallet-mobile-row:not(:first-child) {
  margin-top: 14px;
}

.connect-wallet-row-image-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffff;
}

.connect-wallet-row-image {
  width: 22px;
  height: 22px;
}

.connect-wallet-row-text {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 12px !important;
  color: #ffffff;
  margin-bottom: 0 !important;
}

.metamaskBtn {
  border-radius: 2rem;
  background-color: #ffffff;
  border-width: 0px;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
}

.metamaskBtn img {
  height: 16px;
  width: 16px;
}

.select-source-modal-size {
  width: 474px;
  height: 541px;
}

.btn-white {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 6px;
  padding: 0 12px;
  box-shadow: 0px 0px 16px 0px white;
  border-radius: 40px;
  border: none;
  transition:
    box-shadow 200ms ease,
    transform 200ms var(--delay-transition) 100ms;
  position: relative;
  background-color: white;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #030016;
  will-change: transform;
  text-decoration: none !important;
}

.btn-white--disabled,
.btn-white:disabled {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: none;

  font-size: 14.07px;
  font-weight: 700;
  line-height: normal;
  color: rgba(255, 255, 255, 0.6);
}

.btn-white--disabled {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.btn-white:not(:disabled):hover {
  box-shadow: 0 0 20px 0 rgba(255, 255, 255, 1);

  transform: scale(1.03);
}

.btn-white:not(:disabled):hover::after {
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .select-source-modal-size {
    width: 100%;
  }

  .select-source-modal-max-width {
    max-width: 474px;
  }
}

.header-wallet-button {
  position: relative;
  outline: none;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  /*background-color: #9281FF;*/
}

.header-mobile-button-img-1 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 11px;
}

.header-mobile-button-img-2 {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 11px;
  right: 10px;
}

.header-app-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79px;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 80px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-decoration: none;
  color: #ffffff;
}

.header-mobile-button-background {
  background-image: linear-gradient(90deg, #9281ff, #715aff);
  box-shadow: inset white 0px 0px 7px;
}

.header-mobile-button-background:hover {
  box-shadow:
    0px 0px 30px 4px #ffffff50,
    0px -1px 6px 0px #ffffffcf inset,
    0px 2px 4px 0px #ffeec159 inset !important;
}

.noRoundRight {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.noRoundLeft {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.bold {
  font-weight: bold;
}

.header-tabs {
  display: flex;
}

.header-padding-inline-home-page {
  padding-inline: 40px;
}

.send-bg-img-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}

.header-padding-inline {
  padding-inline: 0;
}

.header-mt {
  margin-top: 28px !important;
}

.terms-privacy-text img {
  object-fit: contain;
}

.footer-icons-display {
  display: flex;
}

.footer-text-display {
  display: flex;
}

.footer-left-padding {
  padding-left: 5px;
}

@media only screen and (max-width: 913px) {
  .headerRight {
    width: 400px !important;
  }
}

@media only screen and (max-width: 992px) {
  .header-tabs {
    display: none;
  }

  .footer-icons-display {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .page-background-color {
    background-color: #1e183f;
  }

  .progressFormBody {
    padding: 0;
  }

  .header-tabs {
    display: none;
  }

  .header-padding-inline-home-page {
    padding-inline: 23px !important;
  }

  .header-mt {
    margin-top: 30px !important;
  }

  .footer-text-display {
    width: 100%;
    justify-content: space-between;
  }

  .footer-left-padding {
    padding-left: 0;
  }

  .footer-container-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mainSend {
    padding-inline: 20px;
  }

  .formBg {
    background: transparent;
    margin-bottom: 0;
  }

  .formBg {
    margin-inline: -20px;
    width: 100vw !important;
    padding: 34px 20px 0 20px;
    background: linear-gradient(180deg, #5b48cf 0%, rgba(0, 0, 0, 1) 100%);
    backdrop-filter: none;
  }

  .send-page-container .formBg::before {
    display: block;
    bottom: 0;
  }

  .send-bg-img-mobile {
    display: block;
  }

  .send-bg-img {
    display: none;
  }

  .page-padding-bottom {
    padding-bottom: 23px !important;
  }

  .transferInfoTitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .separator {
    margin: 32px 0;
  }

  .send-page-footer,
  .progress-page-footer-container {
    position: absolute;
    bottom: 23px;
    padding-left: 14px;
    padding-right: 26px;
    background-color: #1e183f;
  }

  .send-page-footer {
    position: absolute;
    bottom: 23px;
    padding-left: 14px;
    padding-right: 26px;
  }

  .info-div-padding {
    padding-right: 8px !important;
  }

  .circle-bg {
    background-color: #20184d !important;
  }

  .modal-body {
    padding-inline: 0;
  }

  .formModalBg {
    margin-bottom: 0;
  }

  .header-ml {
    margin-left: 2px;
    padding-left: 3px;
  }

  .menu-header-ml {
    margin-left: 2px;
  }

  .gradient-border-mask::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px !important;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-top: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-bottom: 0px solid transparent !important;
    background: linear-gradient(359deg, transparent, rgba(255, 255, 255, 0.5))
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  .modal-content .gradient-border-mask::before {
    bottom: 0 !important;
  }

  .gradient-border-mask-connect-wallet::before {
    content: none;
  }
}

.toast-position {
  top: 6rem !important;
}

.pointer-events-none {
  pointer-events: none;
}

.center-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.self-center {
  align-self: center;
}

.btnGradientMain {
  margin-top: 39px;
  color: white;
  width: 208px;
  height: 50px;
  padding: 12px 18px 12px 18px;
  border-radius: 80px;

  font-weight: 700;
  box-shadow: inset white 0px 0px 5px;
  background: radial-gradient(
    circle,
    rgba(123, 73, 244, 1) 34%,
    rgba(143, 124, 255, 1) 86%,
    rgba(227, 196, 255, 1) 100%
  );
}

.btnGradientMain:hover:not(.btnDisabled) {
  color: #ffffff !important;
  box-shadow:
    0px 0px 30px 4px #ffffff72,
    0px -1px 6px 0px #ffffffcf inset,
    0px 2px 4px 0px #ffeec159 inset !important;
}

.btnDisabled {
  background: rgba(var(--cl-w), 0.1) !important;
  border: 0.5px solid rgba(163, 157, 199, 0.7) !important;
  color: #a39dc7 !important;
  box-shadow: none !important;
  font-weight: 400;
  scale: 1 !important;
}

@keyframes progressAnimation {
  to {
    width: 0;
  }
}

.terms-privacy-text {
  margin-left: 50px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.scale {
  scale: 1;
}

@media only screen and (max-width: 480px) {
  .terms-privacy-text {
    margin-left: unset;
  }
}

@media (min-width: 1900px) {
  .scale {
    scale: 1.2;
  }
}

@media (min-width: 2100px) {
  .scale {
    scale: 1.3;
  }
}

.btn-white {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 6px;
  padding: 0 12px;
  box-shadow: 0px 0px 16px 0px white;
  border-radius: 40px;
  border: none;
  transition:
    box-shadow 200ms ease,
    transform 200ms var(--delay-transition) 100ms;
  position: relative;
  background-color: white;

  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
  color: #030016;
  will-change: transform;
  text-decoration: none !important;
  transition: 0.3s ease-in-out;
}

.btn-white--disabled,
.btn-white:disabled {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: none;

  color: #fff;
}

.btn-white:not(:disabled):hover {
  box-shadow: 0 0 20px 0 rgba(255, 255, 255, 1);

  transform: scale(1.03);
}

.btn-white:not(:disabled):hover::after {
  opacity: 0;
}

.gap-2\.5 {
  gap: 0.75rem;
}

.phases-NFTs-progress {
  position: relative;
  display: flex;
}

.phases-NFTs-progress-item {
  display: flex;
  height: 38px;
  object-fit: contain;
  opacity: 0.7;
  margin-left: -8px;
}

.phases-NFTs-progress-item.active {
  filter: drop-shadow(0px 0px 4px var(--phases-NFTs-item-shadow-color));
  opacity: 1;
}

.phases-NFTs-progress-item:nth-child(1) {
  --phases-NFTs-item-shadow-color: #f8cbab99;
}

.phases-NFTs-progress-item:nth-child(2) {
  --phases-NFTs-item-shadow-color: #d0f4ff80;
}
