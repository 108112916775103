.send-advanced-form--progress .transferInfoTitle {
  margin-bottom: 40px;
}

.send-advanced-form--progress .transactionInfoDableDiv {
  width: 100%;
}

.send-advanced-form--progress .progressActionWrapper {
  max-width: 406px;
}

.send-advanced-form--progress .advanced-transfer-table {
  padding-left: 27px;
  padding-right: 27px;
  max-width: none;
  margin-top: 24px;
}

@media (max-width: 746px) {
  .send-advanced-form--progress .advanced-transfer-table {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.send-advanced-form--progress .advanced-transfer-table--item,
.send-advanced-form--progress .advanced-transfer-table--header {
  gap: 21px;
}

.progress-orders-menu--trigger {
  position: absolute;
  right: -24px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 14px;
  margin-left: 10px;
  width: 14px;
  height: 14px;
  background: transparent;
  border: none;
  padding: 0;
}

.progress-orders-menu--trigger[data-state="open"] svg {
  transform: rotate(-180deg);
}

.progress-orders-menu--anchor {
  padding-right: 32px;
  border: 1px solid transparent;
  transition: border 0.2s ease-in-out;
}

.progress-orders-menu--anchor[data-state="open"] {
  border: 1px solid #ffffff33;
}

.progress-orders-menu--anchor .progress-info-block-price-div {
  width: 100px;
}

.progress-orders-menu {
  position: relative;
  width: var(--radix-popper-anchor-width);
  padding: 16px;
  background: linear-gradient(180deg, rgba(136, 126, 188, 0.2) 0%, rgba(88, 79, 134, 0.2) 106.06%);
  border-radius: 16px;
  backdrop-filter: blur(25px);
  z-index: 10;
  outline: none;
  color: #fff;
  box-shadow: 0px 23px 34px 0px #19133b8f;
}

.progress-orders-menu::before {
  content: "";
  position: absolute;
  inset: 0;
  border: 1px solid #fff;
  border-radius: inherit;
  mask: linear-gradient(191.53deg,
      rgba(255, 255, 255, 0.2) 10.4%,
      rgba(255, 255, 255, 0) 93.6%);
  z-index: 1;
  pointer-events: none;
}

.progress-orders-menu .simplebar-scrollbar::before {
  background-color: #807FB9 !important;
  opacity: 1 !important;
  left: 0;
  right: 0;
  top: 0px !important;
  bottom: 0px !important;
}

.progress-orders-menu .simplebar-vertical {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px !important;
  display: flex;
  justify-content: center;
  width: 4px;
  padding: 0;
}
