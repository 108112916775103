.advanced-form {
  height: auto;
  margin-top: 0 !important;
  max-height: none !important;
  transition-delay: 0s !important;
}

.advanced-form.ignore-animation {
  min-width: 746px;
}

@media screen and (max-width: 768px) {
  .advanced-form.ignore-animation {
    min-width: 0;
  }
}

.advanced-form--disabled {
  margin-top: 66px;
  max-height: 696px;
  transition: 0.3s ease-in-out;
  transition-delay: 0.3s;
}

.advanced-form .formBody {
  /* padding: 32px 50px; */
  transition-delay: 0s !important;
}

.advanced-form--disabled .formBody {
  transition: padding 0.3s ease-in-out;
  transition-delay: 0.3s;
}

.send-advanced-input {
  background-color: #ffffff20;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
}

.send-advanced-input::placeholder {
  color: #a39dc7;
}

.advanced-form .simplebar-scrollbar::before {
  background-color: #807fb9 !important;
  opacity: 1 !important;
  width: 4px !important;
  top: 0 !important;
  left: 0;
  right: 0;
  bottom: 0 !important;
  left: 50%;
  transform: translateX(-50%);
}

.advanced-form .simplebar-vertical {
  width: 4px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px !important;
  display: flex;
  justify-content: center;
}

.advanced-from-input {
  margin-top: 24px;
}

.advanced-from-input-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 20px;
}

.advanced-from-input-header p {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff99;
}

.advanced-from-input-fields {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  margin-bottom: 32px;
  margin-top: 8px;
}

.advanced-mode-header {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.advanced-mode-help {
  display: flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(90deg, #ccc3ff 0%, #e5c4ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none !important;
  transition: opacity 0.3s ease-in-out;
}

.bridge-mode-switch span {
  color: #b5b2c9;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  transition: 0.3s ease-in-out;
}

.bridge-mode-switch span.active {
  transition-delay: 0.6s;
  font-weight: 700;
  color: #fff;
}

.advanced-form .btnGradient:disabled {
  background: #a181ff33 !important;
  box-shadow: none !important;
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 746px) {
  .advanced-form .formBody {
    padding: 10px 20px;
  }

  .advanced-from-input-fields {
    flex-direction: column;
  }

  .advanced-from-input {
    flex-direction: column;
    gap: 8px;
  }
}

@media (max-width: 480px) {
  .advanced-form .formBody {
    padding: 0;
  }
}
