svg .loader-ring {
  transform-origin: 55px 55px;
  animation:
    starting-fill 0.5s forwards,
    vary-loader-width 3s 0.5s linear infinite alternate,
    spin 1.6s 0.2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes starting-fill {
  to {
    stroke-dashoffset: 50;
  }
}

@keyframes vary-loader-width {
  0% {
    stroke-dashoffset: 50;
  }

  50% {
    stroke-dashoffset: 170;
  }

  100% {
    stroke-dashoffset: 260;
  }
}
