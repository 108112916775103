.body {
  background: #030016;
}

.quests-layout {
  display: grid;
  grid-template-columns: 388px 1fr;
  margin: 0 auto;
  gap: 40px;
  padding: 40px;
  max-width: 100vw;
  max-width: 1440px;
  min-height: calc(100vh - 73px - 60px);
  min-height: calc(100svh - 73px - 60px);
  color: #fff;
}

.quests-layout--loader {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: 100svh;
  background: #030016;
  z-index: 100;
}

.quests-layout-footer {
  margin-top: auto;
  padding: 0 40px 16px 40px;
}

.quests-badge {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 10px;
  border-radius: 100px;
  background: rgba(255, 255, 255, .05);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
}

.quests-badge.active {
  background: rgba(255, 255, 255, .05) !important;
}

@media screen and (max-width: 992px) {
  .quests-layout {
    grid-template-columns: minmax(auto, 388px) 1fr;
  }
}

@media screen and (max-width: 768px) {
  .quests-layout {
    gap: 14px;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding: 17px 20px;
  }

  .quests-layout-header {
    position: relative;
    z-index: 20;
  }

  .quests-layout-footer {
    padding: 0 20px 23px 20px;
  }
}
