.native-balance-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  background: #86400E66;
  border: 0.5px solid #F8CBAB4D;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #F8CBAB;
  overflow: hidden;
}

.native-balance-badge .default-tooltip-trigger {
  background: #F8CBAB !important;
  color: #3F2636 !important;
}

.native-balance-tooltip {
  flex-direction: column;
  align-items: flex-start;
  padding: 11px 12px 9px 13px;
  gap: 2px;
  max-width: 240px;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
}

.native-balance-tooltip--link {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #fff !important;
}

.native-balance-tooltip--action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  background: linear-gradient(90deg, #B0A4FF 0%, #E5C4FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none !important;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
}
