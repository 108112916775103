.switch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  min-width: 40px;
  width: 40px;
  padding: 0 4px;
  background: rgba(255, 255, 255, .1) !important;
  border: none;
  border-radius: 100px;
}

.switch[data-state="checked"],
.switch.always-checked {
  background: linear-gradient(96.45deg, #8F7CFF 9.02%, #E5C4FF 108.18%) !important;
}

.switch-thumb {
  display: block;
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, .2);
  border-radius: 100px;
  transition: .3s ease-in-out;
}

.switch-thumb[data-state="checked"] {
  background: #fff;
  transform: translateX(18px);
}

.switch.always-checked .switch-thumb {
  background: #fff;
}
