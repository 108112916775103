.full-page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  width: 100svw;
  height: 100vh;
  height: 100svh;
  background-color: #000;
}
