.dapp-banner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 10px 18px;
  background: linear-gradient(
    269.95deg,
    #c9c4ff 0.03%,
    #6b59db 46.98%,
    #512f9a 99.94%
  );
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none !important;
  color: #fff !important;
}

.dapp-banner--icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  border-radius: 100px;
  background: #fff;
}

.dapp-banner--icon img {
  min-width: 12px;
  width: 12px;
  object-fit: contain;
}
