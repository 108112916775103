.mobile-menu--network-tabs {
  position: relative;
  display: flex;
  width: 169px;
  height: 44px;
  margin-top: 25px;
  padding: 4px;
  background: rgba(255, 255, 255, .1);
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
}

.mobile-menu--network-tabs::before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 83px;
  border-radius: 100px;
  z-index: -1;
  background: linear-gradient(100.22deg, #D7B6F7 -13.27%, #7C6AE9 71.72%);
  transition: .3s ease-in-out;
}

.mobile-menu--network-tabs.testnet::before {
  width: 78px;
  left: 87px;
  background: linear-gradient(100.08deg, #FFB494 5.15%, #B3466D 85.26%);
}

.mobile-menu--network-tabs-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  text-decoration: none !important;
}

.mobile-menu--network-tabs-item:nth-child(1) {
  width: 83px;
}

.mobile-menu--network-tabs-item:nth-child(2) {
  width: 78px;
}
