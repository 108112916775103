.quests-profile--wallet-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  background: transparent;
  border: none;
  color: #fff;
  outline: none;
}

.quests-profile--wallet-trigger img {
  min-width: 24px;
  width: 24px;
  height: 24px;
}

.quests-profile--wallet-action {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 11px 17px;
  background: rgba(255, 255, 255, .05);
  border: none;
  border-radius: 100px;

  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  outline: none;
}

.quests-profile--wallet-action .image-stack {
  isolation: isolate;
  display: flex;
  align-items: center;
}

.quests-profile--wallet-action .image-stack img {
  position: relative;
  min-width: 14px;
  width: 14px;
  height: 14px;
  margin-left: -6px;
  filter: drop-shadow(3px 0px 4px #00000040);
}

.quests-profile--wallet-action .image-stack img:first-child {
  margin-left: 0;
}

.quests-profile--wallet-action .text-gradient {
  background: linear-gradient(90deg, #B19EFF 0%, #D0B5FF 60.18%, #E5C4FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quests-profile--wallet-menu {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: var(--radix-popover-content-available-width);
  max-width: 200px;
  padding: 20px;
  background: #252139;
  border: 0.5px solid rgba(255, 255, 255, .2);
  border-radius: 12px;
  box-shadow: 0px 9px 36.6px 0px #030016;
  outline: none;
  z-index: 40;
  transform-origin: 50% 0%;
}

.quests-profile--wallet-menu--item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.quests-profile--wallet-menu--item-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #A39DC7;
}

.quests-profile--wallet-menu--item-value {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
}

.quests-profile--wallet-menu--item-image {
  min-width: 20px;
  width: 20px;
  height: 20px;
}

.quests-profile--wallet-menu--divider {
  margin: 0;
  border-color: #B5B2C933;
  opacity: 1;
}

.quests-profile--wallet-menu--action {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 auto;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;

  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

.quests-profile--wallet-menu--action.text-gradient {
  background: linear-gradient(90deg, #B19EFF 0%, #D0B5FF 60.18%, #E5C4FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quests-profile--wallet-menu--action.text-danger {
  color: #FF8181 !important;
}

.quests-profile--wallet-trigger-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: -8px;
  padding: 0;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .4);
}

.quests-profile--wallet-trigger-icon:first-child {
  margin-left: 0;
}
