.amount-input {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0 0 12px 12px;
}

.amount-input--label {
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.6);
}

.amount-input--action {
  margin-left: 14px;
  padding: 0;
  background: transparent;
  border: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #a1f2b3;
}

.amount-input--value {
  flex-grow: 1;
  min-width: 0;
  height: auto;
  padding: 0;
  border-radius: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  background: transparent;
}
