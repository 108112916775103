.notifications-menu-profile {
  display: flex;
  padding: 55px 8px 4px 12px;
  gap: 3px;
}

.notifications-menu-profile--banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  z-index: -1;
}

.notifications-menu-profile--banner::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  border: 1px solid #ffffff33;
}

.notifications-menu-profile--image {
  margin-top: -25px;
}

.notifications-menu-profile--image img {
  min-width: 86px;
  width: 86px;
  height: 86px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.notifications-menu-profile--heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.notifications-menu-profile--progress {
  width: 100%;
  height: 4px;
  border-radius: 36px;
  background: rgba(255, 255, 255, 0.1);
}

.notifications-menu-profile--progress-bar {
  height: 100%;
  max-width: 100%;
  border-radius: 36px;
  background: linear-gradient(90deg, #9e86ff 0%, #e1c9ff 100%);
}

.notifications-menu-profile--hint {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #8984ac;
}

.notifications-menu-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: auto;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.notifications-menu-stats > div:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.notifications-menu-stats > div {
  padding: 7px 0 11px 0;
}

.notifications-menu-stats--value {
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
}

.notifications-menu-stats--label {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: rgba(137, 132, 172, 1);
}

.notifications-menu-profile--placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 0 20px 0;
  background: url(../assets/ProfileBg.png) no-repeat;
  background-size: cover;
  border-radius: inherit;
}

.notifications-menu-profile--placeholder-image {
  height: 60px;
}

.notifications-menu-profile--placeholder-heading {
  max-width: 224px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.notifications-menu-profile--placeholder-action {
  min-width: 154px;
  height: 40px;
}

.text-purple {
  color: #8984ac;
}

@media screen and (max-width: 768px) {
  .notifications-menu-profile {
    padding: 55px 8px 4px 12px;
  }

  .notifications-menu-profile--heading {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}
