.default-tooltip-trigger,
.fee-tooltip-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 12px;
  width: 12px;
  height: 12px;
  padding: 0;
  margin-right: auto;
  margin-left: 6px;
  background: rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 100px;
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.fee-tooltip-trigger {
  display: inline-flex;
}

.default-tooltip-trigger[data-state='delayed-open'],
.fee-tooltip-trigger[data-state='delayed-open'] {
  background: linear-gradient(125.54deg, #8f7ff0 19.1%, #c178fa 112.11%);
}

.default-tooltip,
.fee-tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 64px;
  padding: 12px 8px 20px 15px;
  border-radius: 12px;
  z-index: 100000;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #b5b2c9;
}

.default-tooltip {
  --background-color: #4d476c;
  width: 212px;
  height: auto;
  min-height: 54px;
  padding: 12px 8px 12px 15px;
}

.dark.default-tooltip {
  --background-color: #29234E;
}

.dark-2.default-tooltip {
  --background-color: rgba(29, 23, 56, .5);
  width: auto;
}

.flat.default-tooltip {
  max-width: 195px;
  padding: 10px 16px;
  --background-color: #B1A3E833;
  width: auto;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.default-tooltip-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.5px solid #FFFFFF40;
  background: var(--background-color);
  border-radius: inherit;
  z-index: -1;
}

.dark-2 .default-tooltip-bg {
  backdrop-filter: blur(120px);
}

.flat .default-tooltip-bg {
  border: none;
}

.default-tooltip-arrow {
  position: relative;
  transform: translateY(-1px);
  color: var(--background-color);
}

.dark-2 .default-tooltip-arrow {
  color: #322d53;
}

.dark-2.advanced-open .default-tooltip-arrow {
  color: #201B3E;
}

.flat .default-tooltip-arrow polygon {
  fill: #B1A3E833;
  transform: translateY(0.1px);
}

.flat .default-tooltip-arrow polygon:nth-child(2) {
  display: none;
}

.fee-tooltip-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 68px;
  z-index: -1;
}
